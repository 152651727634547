import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { GoHome } from 'react-icons/go';
import { client, urlFor } from '../sanity/sanityClient';
import { useStateContext } from '../context/StateContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';
import Cart from './Cart';
import { AiOutlineShopping } from 'react-icons/ai';
import Loader from './Loader';

const CategoryItems = () => {
  const { categoryName } = useParams();
  const { totalQuantities, showCart, setShowCart, onAdd } = useStateContext();
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndices, setCurrentIndices] = useState([]);
  const [quantities, setQuantities] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        setIsLoading(true);
        const data = await client.fetch(`*[_type == '${categoryName}']`);
        setSubCategories(data);
        setQuantities(data.map(() => 1)); // Initialize quantity for each product
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, [categoryName]);

  useEffect(() => {
    setCurrentIndices(Array(subCategories.length).fill(0));
  }, [subCategories]);

  const handleAddToCart = (product, index) => {
    const focusedImage = product.image[currentIndices[index]]; // Get the image in focus
    const productWithFocusedImage = { ...product, image: [focusedImage] }; // Create a copy with only the focused image
    onAdd(productWithFocusedImage, quantities[index]);
    
    toast.success(`${quantities[index]} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleSlide = (index, direction) => {
    setCurrentIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      const productImagesLength = subCategories[index].image.length;
      newIndices[index] = (newIndices[index] + direction + productImagesLength) % productImagesLength;
      return newIndices;
    });
  };

  const incQty = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((qty, i) => (i === index ? qty + 1 : qty))
    );
  };

  const decQty = (index) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((qty, i) => (i === index ? Math.max(1, qty - 1) : qty))
    );
  };

  return (
    <>
      <div style={{ backgroundColor: '#00000076', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Link to={'/'} style={{ color: 'white', textDecoration: 'none' }}><GoHome />Home</Link>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}>
          <Cart />
        </div>
      </div>
      <div className='itemListTwo'>
        <ToastContainer />
        {isLoading ? (
          <Loader />
        ) : subCategories.length > 0 ? (
          subCategories.map((product, index) => (
            <div key={product._id} className="imgGape">
              <div className="small-images-container">
                {product.soldout && (
                  <span style={{ position: 'absolute', top: 5, left: 0, backgroundColor: 'rgba(0,0,0,0.5)', color: 'red', padding: '5px', borderRadius: '5px' }}>
                    {product.soldout}
                  </span>
                )}
                {product.discount && (
                  <span style={{ position: 'absolute', top: 5, left: 230, whiteSpace: 'nowrap', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px', borderRadius: '5px' }}>
                    {product.discount}
                  </span>
                )}
                {product.image.map((image, i) => (
                  <span key={i} className={i === currentIndices[index] ? '' : 'hide'}>
                    <img className="small-image-two" src={urlFor(image)} alt={product.name} />
                  </span>
                ))}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <GoChevronLeft className='left-arrow' style={{ color: 'red' }} onClick={() => handleSlide(index, -1)} />
                <GoChevronRight className='right-arrow' style={{ color: 'red' }} onClick={() => handleSlide(index, 1)} />
              </div>
              <div className="result-display">
                <p style={{ color: 'red' }}>Tzs:{product.price.toLocaleString()}/=</p>
                <p>{product.details}</p>
              </div>
              <div style={{ display: 'block', margin: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                  <div className="reviews">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <AiOutlineStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                    </div>
                    <p>(4)</p>
                    <span className="quantity" style={{ display: 'flex', alignItems: 'center', borderRadius: '10px' }}>
                      <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => decQty(index)}>
                          <AiOutlineMinus />
                        </span>
                        <span style={{ margin: '0 10px' }}>{quantities[index]}</span>
                        <span style={{ cursor: 'pointer' }} onClick={() => incQty(index)}>
                          <AiOutlinePlus />
                        </span>
                        <button
                          type="button"
                          className="add-to-cart"
                          style={{ cursor: 'pointer', backgroundColor: '#f02d34', borderRadius: '10px', marginLeft: '10px' }}
                          onClick={() => handleAddToCart(product, index)}
                        >
                          Add to Cart
                        </button>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p style={{ textAlign: 'center' }}><strong>No products in this category yet</strong></p>
        )}
      </div>
    </>
  );
};

export default CategoryItems;
