// Scraper.jsx
import React, { useState, useEffect} from 'react';
//import axios from 'axios';
import { GoSearch } from 'react-icons/go';
import Loader from './Loader';
import SearchProduct from './SearchProduct';
import { urlFor } from '../sanity/sanityClient';
import writer from '../assets/writer.svg';
import logo192 from './logo192.jpg';
import Amazon from './Amazon.png';
import Alibaba from './Alibaba.jpg';


export default function Scraper ({onSelectedProduct, categories, isLoading}){
  // const amazonAPI="";
  // const bridgeAPI="";
  // const aliExpressAPI="";
  // const alibabaAPI="";
  const [searchQuery, setSearchQuery] = useState('');
  const [name, setName]=useState('BridgenApp');
  const [searching, setSearching]=useState(false);
   const [searchResults, setSearchResults]=useState([]);


  // const [searchAPI, setSearchAPI]=useState(bridgeAPI);
  // const [bridge, setBridge]=useState(false);
  // const [amazon, setAmazon]=useState (false);
  // const [alibaba, setAlibaba]=useState(false);
  // const [aliExpress, setAliExpress]=useState(false);
  
  
  // const handleAmazon=()=>{
  //   setName('Amazon');
  //   setSearchAPI(amazonAPI);
  //   setAmazon(true);
  //   setBridge(false);
  //   setAliExpress(false);
  //   setAliExpress(false);

  // }

   
useEffect(() => {
    if (searchQuery.length>0){
       const filteredResults = categories.filter((category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filteredResults);
    setSearching(true);
  }else {
    setSearchResults([]);
    setSearching(false);
  }
   
  }, [categories, searchQuery]);

  const handleProductClick = (product) => {
    //setSelectedProduct(result.urls.regular);
    onSelectedProduct(product.image[0].asset._ref);
  };

  return (
    <div className='market' style={{display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'center',
  height: '90vh'}}
      >
    <div className="upper-div">
      <img src={logo192} style={{width:30, height:20, padding:10, borderRadius:10, marginTop:0}} 
      alt='BridgeApp_logo' onClick={(e) => setName('BridgenApp')}/>
      <img src={Amazon} style={{width:40, height:20, padding:10, borderRadius:10, marginTop:0}} 
      alt='Amazon_logo' onClick={(e) => setName('Amazon')}/>
      <img src={Alibaba} style={{width:40, height:20, padding:10, borderRadius:10, margin:0}} 
      alt='Alibaba_logo'onClick={(e) => setName('Alibaba')}/>
      

      <div className="search-form" style={{display:'flex', alignItems:'center', justifyContent:'center',width:'100%',marginLeft:'8%', borderRadius:'10px', backgroundColor:'#f0f0f0', border:'solid'}}>
        <div><GoSearch/></div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          required
          placeholder={`Search ${name} surprises`}
          style={{width:'95%', height:'20px', borderRadius:'5px', border:'none', outline:'none'}}
        />
             </div>

        {searching && searchResults.length>0 && <SearchProduct searchResults={searchResults} handleProductClick={handleProductClick}/>}

       </div>
      <div className="search-results-two">
      {isLoading && categories.length <= 0 ? (
          <Loader />
        ) : !isLoading && categories.length <= 0 ? (
          <div>
            <img src={writer} width={300} height={300} alt="no-internet" />
            <h3>No Internet connection</h3>
            <p style={{ display: 'flex' }}>Check your connection, then refresh the page</p>
          </div>
        ):(
          categories.map((product) => (
            <div key={product._id} 
            className="result-item">
                {product.soldout? <span style={{position:'absolute', top:5, left:0, backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'red', padding:'5px', borderRadius:'5px'}}>{product.soldout}</span>:''}
                  {product.discount? <span style={{position:'absolute', top:5, left:100,whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'white', padding:'5px', borderRadius:'5px'}}>{product.discount}</span>:''} 
                 <img 
                 src={urlFor(product.image[0])}
                 product={urlFor(product.image[0])} 
                 alt='loaded'
                 onClick={() => handleProductClick(product)}
                 />

               <div className="result-display">
                  <p style={{fontSize:'small', color:'red'}}>Tzs:<strong>{product.price.toLocaleString()}/=</strong></p>
                  </div>
                
              </div>
               
          )).reverse()
        )} <br/>
      </div>
      <div className="lower-div">
      <button className="trendy-button">Be Trendy, Stay Trendy!</button>
      </div>
    </div>
  );
}






