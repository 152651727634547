import React, { useState, useEffect } from 'react';
import { client } from '../sanity/sanityClient';
import {v4 as uuidv4} from 'uuid';
import './ProductForm.css';

const ProductForm = () => {
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [details, setDetails] = useState('');
    const [location, setLocation]=useState('');
    const [images, setImages] = useState([]);
    const [categories] = useState([
          'clothingandfashion', 'electronics', 'kitchen', 'crops',  'fashion',
          'grocery',  'vehicles',
        'foodandbeverages', 'homeandfurnitures',  'medical', 
    ]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch the category ID based on selectedCategory
    useEffect(() => {
        const fetchCategoryId = async () => {
            try {
                if (selectedCategory) {
                    const result = await client.fetch(
                        `*[_type == $selectedCategory][0]{ _id }`,
                        { selectedCategory }
                    );
                    if (result) {
                        setCategoryId(result._id);
                    } else {
                        setErrorMessage(`Category "${selectedCategory}" not found.`);
                    }
                }
            } catch (error) {
                setErrorMessage('Error fetching category ID');
                console.error('Error fetching category ID:', error.message);
            }
        };

        fetchCategoryId();
    }, [selectedCategory]);

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        if (!categoryId) {
            setErrorMessage('Select a valid category before adding a product.');
            setIsLoading(false);
            return;
        }

        try {
            const imageIds = await uploadImages(images);
            const slugValue = generateSlug(productName);

            const newProduct = await client.create({
                _type: selectedCategory,
                name: productName,
                slug: { _type: 'slug', current: slugValue },
                price: Number(price),
                details,
                location,
                image : imageIds.map((id) => ({
                    _type: 'image',
                    _key: uuidv4(),
                    asset: { _type: 'reference', _ref: id }
                })),
            });

            setSuccessMessage('Product added successfully!');
            resetForm();
            console.log('Product created:', newProduct);
        } catch (error) {
            setErrorMessage('Failed to add the product. Please try again.');
            console.error('Error adding product:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setProductName('');
        setPrice('');
        setDetails('');
        setLocation('');
        setImages([]);
        setSelectedCategory('');
        setCategoryId(null);
    };

    const generateSlug = (name) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/(^-|-$)+/g, '');
    };

    const uploadImages = async (images) => {
        const imageIds = [];
        for (let image of images) {
            try {
                const asset = await client.assets.upload('image', image, { filename: image.name });
                imageIds.push(asset._id);
            } catch (error) {
                console.error('Image upload failed:', error.message);
                throw new Error('Image upload failed');
            }
        }
        return imageIds;
    };

    return (
        <div className="product-form-container">
            <h1 className="form-title">Add New Product</h1>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <form className="product-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Product Name:</label>
                    <input
                        type="text"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Price:</label>
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Details:</label>
                    <textarea
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Location:</label>
                    <input
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Category:</label>
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        required
                    >
                        <option value="">Select a category</option>
                        {categories.map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Images:</label>
                    <input type="file" multiple onChange={handleImageChange} />
                </div>
                <div className="image-preview">
                      {images.map((image, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(image)}
                          alt="Preview"
                          className="preview-image"
                        />
                      ))}
                    </div>
                <button className="submit-button" type="submit" disabled={isLoading}>
                    {isLoading ? 'Adding Product...' : 'Add Product'}
                </button>
            </form>
        </div>
    );
};

export default ProductForm;
