import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../sanity/sanityClient';
import { v4 as uuidv4 } from 'uuid';
import { GoArrowLeft } from 'react-icons/go';
import './UploadForm.css';

const UploadForm = ({ currentUser, changeCategory }) => {
  const [title, setTitle] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]); // State to store fetched posts

  // Handle image selection and store files in state
  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setImages(selectedFiles);
  };

  // Fetch all documents from the 'featured' schema
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const query = '*[_type == "posts"]';
        const result = await client.fetch(query);
        setPosts(result);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Upload each image and get its asset reference
      const imageUploads = await Promise.all(
        images.map((image) => client.assets.upload('image', image))
      );
      const slugValue = generateSlug(title);

      // Construct the document to send to Sanity
      const doc = {
        _type: 'posts',
        avatar: currentUser.avatarImage,
        user: currentUser._id,
        name: currentUser.username,
        title,
        slug: { _type: 'slug', current: slugValue },
        image: imageUploads.map((upload) => ({
          _type: 'image',
          _key: uuidv4(),
          asset: { _ref: upload._id, _type: 'reference' },
        })),
      };

      await client.create(doc);
      resetForm();

      // Fetch updated posts after successful upload
      const updatedPosts = await client.fetch('*[_type == "posts"]');
      setPosts(updatedPosts);
    } catch (error) {
      console.error("Error uploading to Sanity:", error);
      alert('Upload failed!');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setImages([]);
  };

  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '');
  };

  return (
    <div className="upload-form">
      <form onSubmit={handleSubmit}>
      <h4 ><button  style={{height:'20px'}}onClick={changeCategory}><GoArrowLeft/>
        </button>You are Here, the Place to Talk Business</h4>
         <small>Share your personal brand or business brand and close deals</small>
        <input
          type="text"
          placeholder="Enter title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="input-title"
          required
        />

        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          className="input-file"
        />

        <div className="image-preview">
          {images.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="preview-image"
            />
          ))}
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Uploading...' : 'Post'}
        </button>
      </form>

      {/* Display fetched posts */}
      <div className="posts-list">
        {posts.length > 0 ? (
          posts.map((post) => (
            <PostCard key={post._id} post={post} />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

// Slider-enabled PostCard component
const PostCard = ({ post }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.image.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === post.image.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="post-card">
      <div className="user-credentials">
        {post.avatar && (
          <img
            src={`data:image/svg+xml;base64,${post.avatar}`}
            alt={`${post.name}'s avatar`}
            className="avatar-image"
          />
        )}
        <p className="user-name">{post.name}</p>
      </div>

      <div className="post-images">
        {post.image && post.image.length > 0 && (
          <div className="image-slider">
            <img
              src={urlFor(post.image[currentImageIndex])}
              alt={`Uploaded by ${post.name}`}
              className="post-image"
            />
            {post.image.length > 1 && (
              <>
                <button
                  className="slider-button prev-button"
                  onClick={handlePrevImage}
                >
                  ❮
                </button>
                <button
                  className="slider-button next-button"
                  onClick={handleNextImage}
                >
                  ❯
                </button>
              </>
            )}
          </div>
        )}
      </div>

      <h5>{post.title}</h5>
    </div>
  );
};

export default UploadForm;
