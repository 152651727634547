import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CategoriesDisplay from './CategoriesDisplay';
import Cart from './Cart';
import { useStateContext } from '../context/StateContext';
import { AiOutlineShopping } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import writer from '../assets/writer.svg';
import logo192 from './logo192.jpg';
import Amazon from './Amazon.png';
import Loader from './Loader';
import SearchForm from './SearchForm';
import { Sendrequest, onMessageListener } from '../firebase';
import Aliexpress from './Aliexpress.png';
import './App.css';

const Categories = ({ categories, isLoading, changeCategory, currentUser, showCntcts }) => {
  const { showCart, setShowCart, totalQuantities } = useStateContext();
  const [name, setName] = useState('BridgenApp');
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allowedUsers] = useState(['65c8769cdd3056908f574cc1', 
  '66d191a34b88f0eba97d33f4', '66ccdf93b813a896ca6332aa', '657d60c2e11554ed0ddbdc31','659ec48c8dd04324e05c32eb', 
  '66bdb504193ef4278b14c2ef', '65ce58a1d818ea76d446f2db', '65919a251f53d56bda2a34ba',
  '65ae17462171cecab6b98816','65b3b6f0ac14e14d19baa7f2','6728f4e828e201d93da877c8',
  '65a57e33eeecb777db57ea1e', '6724cb8d29899d8e04a4a7d6']); // Add allowed user IDs here for testing

  const canAccessAddProduct = allowedUsers.includes(currentUser?._id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Sendrequest();
        const payload = await onMessageListener();
        console.log({
          title: payload?.title,
          body: payload?.body,
        });
      } catch (err) {
        console.error('Failed:', err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filteredResults = categories.filter((category) =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(filteredResults);
      setSearching(true);
    } else {
      setSearchResults([]);
      setSearching(false);
    }
  }, [categories, searchQuery]);

  return (
    <div className="app-container" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <div className="market" style={{ width: '100%', backgroundColor: '#00000076' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', textAlign: 'right', top: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
          <div style={{ backgroundColor: 'white', height: '20px' }} onClick={showCntcts}><FiMenu /></div>
           {canAccessAddProduct ? (
              
                <Link to={`/addproduct/${currentUser._id}`} style={{ color: 'white', textDecoration: 'none' }}>
                  Sell Product
                </Link>
              
            ) : (
              
                <span
                  onClick={() => alert("Please Chat with Admin to Sell Products.")}
                  style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}
                >
                  Sell Product
                </span>
              
            )}
          <button onClick={changeCategory} style={{ height: '20px' }}>Branding</button>
          
          <div className="installContainer">
            <button id="butInstall" style={{ display: 'none' }}>Install App</button>
          </div>
          <button type="button" className="cart-icon" onClick={() => setShowCart(true)}>
            <AiOutlineShopping />
            <span className="cart-item-qty">{totalQuantities}</span>
          </button>
          <div className={`modal ${showCart ? 'active' : ''}`}><Cart /></div>
        </div>
        <br />
        <div className="search-form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', marginLeft: '8%', borderRadius: '10px', backgroundColor: '#f0f0f0', border: 'solid' }}>
          <div><GoSearch /></div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            required
            placeholder={`Search ${name} items`}
            style={{ width: '80%', height: '20px', borderRadius: '5px', border: 'none', outline: 'none' }}
          />
        </div>
        <br />
        {searching && <SearchForm searchResults={searchResults} />}
      </div>
      <div className="search-results">
        {isLoading && categories.length <= 0 ? (
          <Loader />
        ) : !isLoading && categories.length <= 0 ? (
          <div>
            <img src={writer} width={300} height={300} alt="no-internet" />
            <h3>No Internet connection</h3>
            <p style={{ display: 'flex' }}>Check your connection, then refresh the page</p>
          </div>
        ) : (
          <CategoriesDisplay categories={categories} />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={logo192} style={{ width: 30, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="BridgeApp_logo" onClick={() => setName('BridgenApp')} />
        <img src={Amazon} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="Amazon_logo" onClick={() => setName('(Not enabled)')} />
        <img src={Aliexpress} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, margin: 0 }} alt='Aliexpress_logo' onClick={() => setName('(Not yet)')} />
      </div>
      <div className="footer" style={{ textAlign: 'center' }}>
        <p>Copyright © 2024 BridgenApp.</p>
      </div>
    </div>
  );
};

export default Categories;
