import imageUrlBuilder from '@sanity/image-url';
import {createClient} from '@sanity/client';


export const client =createClient({
    projectId:process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID,
    dataset:'production',
    useCDN: true,
    apiVersion: '2023-05-03',
    token:process.env.REACT_APP_SANITY_STUDIO_TOKEN,
    
});
const builder = imageUrlBuilder(client);
export const urlFor = (source) => builder.image(source);
