import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { client, urlFor } from '../sanity/sanityClient';
import './TraderBrand.css';

const TraderBrand = () => {
  const { userbrandId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      try {
        // Fetch all products without filtering by userbrandId
        const query = `*[]`;

        const result = await client.fetch(query);
        
        if (userbrandId ==="657d60c2e11554ed0ddbdc31"){
            setProducts(result);
        }else {
        const filteredProducts = result.filter((product) => product.user === userbrandId);
        setProducts(filteredProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [userbrandId]);

  // Function to delete a product by its _id
  const deleteProduct = async (productId) => {
    try {
      await client.delete(productId);
      // Update the products state after deletion
      setProducts(products.filter((product) => product._id !== productId));
      alert('Product deleted successfully');
    } catch (error) {
      console.error("Error deleting product:", error);
      alert('Failed to delete product');
    }
  };

  if (loading) return <p className="noUserProducts">Loading products...</p>;

  return (
    <div className="product-list">
      {products.length > 0 ? (
        products.map((product) => (
          <div key={product._id} className="product-card">
            {product.image && (
              <img src={urlFor(product.image[0])} alt={product.name} className="product-image" />
            )}
            <h3 className="product-name">{product.name}</h3>
            <p className="product-price">Tsh {product.price}</p>
            <p className="product-user">{product.user}</p>
            <button
              onClick={() => deleteProduct(product._id)}
              className="delete-button"
            >
              Delete
            </button>
          </div>
        ))
      ) : (
        <p className="noUserProducts">No products found for this user.</p>
      )}
    </div>
  );
};

export default TraderBrand;
